// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image{
    width: 100%;
    height: auto;
    display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/Carte/Carte.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,cAAc;AAClB","sourcesContent":[".image{\n    width: 100%;\n    height: auto;\n    display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
