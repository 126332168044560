// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    text-align: center;
    padding: 20px;
    background-color: #F5F5DC;
}

.title {
    color: #333;
    margin-top: 20px;
}

.image {
    max-width: 40%;
    border-radius: 10px;
}

.text {
    font-size: 18px;
    color: #555;
}

.list {
    list-style-type: disc;
    margin: 20px auto;
    text-align: left;
    max-width: 600px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Accueil.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,WAAW;AACf;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".container {\n    text-align: center;\n    padding: 20px;\n    background-color: #F5F5DC;\n}\n\n.title {\n    color: #333;\n    margin-top: 20px;\n}\n\n.image {\n    max-width: 40%;\n    border-radius: 10px;\n}\n\n.text {\n    font-size: 18px;\n    color: #555;\n}\n\n.list {\n    list-style-type: disc;\n    margin: 20px auto;\n    text-align: left;\n    max-width: 600px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
