// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.barreNavigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    padding: 10px 20px;
    color: #fff;
  }
  
  .barreNavigation-logo {
    font-size: 24px;
    color: #FFD700; /* Jaune pour rester dans la charte graphique */
    text-transform: uppercase;
  }
  
  .barreNavigation-lien {
    list-style: none;
    display: flex;
    gap: 15px;
  }
  
  .barreNavigation-lien li a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    transition: color 0.3s;
  }
  
  .barreNavigation-lien li a:hover {
    color: #FFD700; /* Couleur jaune au survol */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/BarreNavigation.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,eAAe;IACf,cAAc,EAAE,+CAA+C;IAC/D,yBAAyB;EAC3B;;EAEA;IACE,gBAAgB;IAChB,aAAa;IACb,SAAS;EACX;;EAEA;IACE,qBAAqB;IACrB,WAAW;IACX,iBAAiB;IACjB,sBAAsB;EACxB;;EAEA;IACE,cAAc,EAAE,4BAA4B;EAC9C","sourcesContent":[".barreNavigation {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background-color: #333;\n    padding: 10px 20px;\n    color: #fff;\n  }\n  \n  .barreNavigation-logo {\n    font-size: 24px;\n    color: #FFD700; /* Jaune pour rester dans la charte graphique */\n    text-transform: uppercase;\n  }\n  \n  .barreNavigation-lien {\n    list-style: none;\n    display: flex;\n    gap: 15px;\n  }\n  \n  .barreNavigation-lien li a {\n    text-decoration: none;\n    color: #fff;\n    font-weight: bold;\n    transition: color 0.3s;\n  }\n  \n  .barreNavigation-lien li a:hover {\n    color: #FFD700; /* Couleur jaune au survol */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
